import {Get} from "../server";

export function getUserInfo() {
    return Get("/api1/member/getInfo");
}

export function logout() {
    return Get("/api1/member/logout");
}

export const member = {
    getUserInfo,
    logout
};
