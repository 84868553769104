import {Post} from "../server";

export function list(data= []) {
    return Post("/api1/robotEvent/list", data);
}

export function save(data) {
    return Post("/api1/robotEvent/save", data);
}

export function remove(data) {
    return Post("/api1/robotEvent/delete", data);
}

export const robotEvent = {
    list,
    save,
    remove
}