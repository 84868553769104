import {Post, Get} from "../server";

export function list(data= []) {
    return Get("/api1/robot/list", data);
}


export function getStatus(data= []) {
    return Get("/api1/robot/getStatus", data);
}


export function getById(data) {
    return Post("/api1/robot/getById", data);
}

export function remove(data) {
    return Post("/api1/robot/delete", data);
}

export function update(data) {
    return Post("/api1/robot/update", data);
}

export const robot = {
    list,
    getById,
    remove,
    update,
    getStatus,
};
