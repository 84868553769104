import {Post} from "../server";

export function list(data = []) {
    return Post("/api1/RobotFiles/list", data);
}

export function addUrls(data) {
    return Post("/api1/RobotFiles/addUrls", data);
}

export function remove(id) {
    return Post("/api1/RobotFiles/delete", {id});
}


export function status(data) {
    return Post("/api1/RobotFiles/status", data);
}

export function parseUrl(url) {
    return Post("/api1/RobotFiles/parseUrl", {url});
}

export function parseSiteMap(url) {
    return Post("/api1/RobotFiles/parseSiteMap", {url});
}

export function reference(robot_id, query, msg_id ) {
    return Post("/api1/chat/reference", {robot_id, query, msg_id});
}

export function confirmFiles(data) {
    return Post("/api1/RobotFiles/confirmFiles", data);
}

export const robotFiles = {
    list,
    parseUrl,
    addUrls,
    remove,
    status,
    reference,
    parseSiteMap,
    confirmFiles

};
