import {Post} from "../server";

// 获取机器人产品列表 不传产品id为新增， 传产品id为升级
export function add(product_id) {
    return Post("/api1/newOrders/add", {product_id: product_id});
}

export function addRenew(robot_id){
    return Post("/api1/newOrders/addRenew", {robot_id: robot_id});
}

export function addUpgrade(robot_id, product_id){
    return Post("/api1/newOrders/addUpgrade", {robot_id: robot_id, product_id: product_id});
}

export function getOrder(order_id){
    return Post("/api1/newOrders/getOrder", {order_id: order_id});
}

export function getQrCode(order_id) {
    return Post("/api1/newOrders/getQrCode", {order_id: order_id});
}

export function isOrderPayed(order_id) {
    return Post("/api1/newOrders/isOrderPayed", {order_id: order_id});
}

export const newOrders = {
    add,
    addRenew,
    addUpgrade,
    getOrder,
    getQrCode,
    isOrderPayed
};