import {Post} from "@/api-js/server";

export function list(data) {
    return Post("/api1/RobotConversation/list", data);
}

export function getById(id) {
    return Post("/api1/RobotConversation/getById", {id});
}

export const conversation = {
    list,
    getById
}