import { member } from "./path/user";
import {common} from "./path/common";
import {robot} from "./path/robot";
import {robotFiles} from "./path/robotFiles";
import {robotProducts} from "@/api-js/path/robotProducts";
import {newOrders} from "@/api-js/path/newOrders";
import {chat} from "@/api-js/path/chat";
import {robotEvent} from "@/api-js/path/robotEvent";
import {conversation} from "@/api-js/path/conversation";



export const api = {
  member,
  common,
  robot,
  robotFiles,
  robotProducts,
  newOrders,
  chat,
  robotEvent,
  conversation,
};
