import {Post} from "../server";

export function createConversation(data) {
    return Post("/api1/chat/createConversation", data);
}

export function createMessage(data) {
    return Post("/api1/chat/createMessage", data);
}

export function like(conversation_id) {
    return Post("/api1/chat/like", {conversation_id});
}

export function unlike(conversation_id) {
    return Post("/api1/chat/unlike", {conversation_id});
}

export function userSubmitEvent(data) {
    return Post("/api1/chat/userSubmitEvent", data);
}

export function getConversationById(id) {
    return Post("/api1/chat/getConversationById", {id});
}

export function getRobotByCode(data){
    return Post("/api1/chat/getRobotByCode", data);
}

export function getRobotByCodeAndPwd(data){
    return Post("/api1/chat/getRobotByCodeAndPwd", data);
}



export const chat=  {
    createConversation,
    createMessage,
    like,
    unlike,
    userSubmitEvent,
    getConversationById,
    getRobotByCode,
    getRobotByCodeAndPwd,
}