import {Post} from "../server";

export function login(data) {
    return Post("/api1/common/login", data);
}

export function sendVerifyCode(data){
    return Post("/api1/common/sendVerifyCode", data);
}




export const common = {
    login,
    sendVerifyCode,
};
